
import { defineComponent, ref, computed } from 'vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useRoute } from 'vue-router'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    TmSearch,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Search activities',
    },
    hideSearch: {
      type: Boolean,
    },
    search: {
      type: String,
    },
    hideToggle: {
      type: Boolean,
    },
    activities: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:search', 'toggleExpand'],
  setup() {
    const { openModal } = useModals()
    const showMobileSearch = ref(false)
    const showMobileFilter = ref(false)
    const route = useRoute()
    const activePage = computed(() => route.meta.title)

    return {
      openModal,
      showMobileSearch,
      showMobileFilter,
      activePage,
    }
  },
})
