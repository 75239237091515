
import { defineComponent, ref } from 'vue'
import TimelineCard from '@/components/shared/templates/TimelineCard.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import Highlight from '@/components/shared/Highlight.vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import type { ActivityAssigneeType } from '@/definitions/shared/templates/activity/types'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'NoteCard',
  components: {
    TmDropdownItem,
    TmButton,
    Highlight,
    TmTooltip,
    TmButtonMore,
    TmDropdown,
    TimelineCard,
  },
  props: {
    search: {
      type: String,
    },
    assignee: {
      type: Object as PropType<ActivityAssigneeType>,
    },
    title: {
      type: String,
    },
    opened: {
      type: Boolean,
    },
    pinned: {
      type: Boolean,
    },
  },
  emits: ['toggle-open-state', 'update:pinned'],
  setup() {
    const dropdownValue = ref(false)

    return {
      dropdownValue,
    }
  },
})
