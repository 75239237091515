
import { defineComponent, ref, computed } from 'vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'
import ActivityComponent from '@/components/shared/templates/activity/ActivityComponent.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { checkMatch } from '@/services/string'
import type { PropType } from 'vue'
import TmEmptyState from '@/components/shared/TmEmptyState.vue'
import { useModes } from '@/compositions/modes'
import type { ActivityCardType } from '@/definitions/shared/templates/activity/types'

export default defineComponent({
  name: 'ContactDetailsActivitiesList',
  components: {
    TmEmptyState,
    TmExpansion,
    ActivityComponent,
    TmButton,
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    filters: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    activities: {
      type: Array as PropType<ActivityCardType[]>,
      default: () => [],
    },
  },
  emits: ['update:activities'],
  setup(props, context) {
    const pinnedValue = ref(true)
    const openActivitiesValue = ref(true)
    const pastActivitiesValue = ref(true)
    const pastVisibleCount = ref(5)
    const pinnedVisibleCount = ref(5)
    const { isEmptyMode } = useModes()

    const updateActivityCard = (card: ActivityCardType) => {
      context.emit('update:activities', props.activities.map((e: ActivityCardType) => e.id === card.id ? card : e))
    }

    const filterFunction = (e: ActivityCardType) => {
      // return (checkMatch(e.message, props.search) || checkMatch(e.title, props.search)) && props.filters.includes(e.type)
      return (checkMatch(e.message, props.search) || checkMatch(e.title, props.search))
    }

    const activitiesFiltered = computed(() => props.activities.filter(filterFunction))

    const openActivitiesFiltered = computed<ActivityCardType[]>(() => activitiesFiltered.value.filter((e: ActivityCardType) => e.cardState === 'open'))
    const pastActivitiesPreFiltered = computed<ActivityCardType[]>(() => activitiesFiltered.value.filter((e: ActivityCardType) => e.cardState === 'past'))

    const pastActivitiesFiltered = ref<any>({})
    pastActivitiesPreFiltered.value.forEach((event) => {
      const date = new Date(event.date)
      const month = date.toLocaleString('en-US', { month: 'long' })
      const year = date.getFullYear()
      const key = month + ' ' + year
      if (!pastActivitiesFiltered.value[key]) {
        pastActivitiesFiltered.value[key] = []
      }
      pastActivitiesFiltered.value[key].push(event)
    })

    const pinnedFiltered = computed<ActivityCardType[]>(() => activitiesFiltered.value.filter((e: ActivityCardType) => e.pinned))

    const isUnfoldedAll = computed(() => {
      return pinnedValue.value &&
        openActivitiesValue.value &&
        pastActivitiesValue.value
    })

    const toggleExpand = () => {
      const val = !isUnfoldedAll.value
      pinnedValue.value = val
      openActivitiesValue.value = val
      pastActivitiesValue.value = val
      // pinned.value.data.forEach((el: any) => { el.opened = val })
    }

    const toggleOpenState = (id: number) => {
      // pinned.value.data = pinned.value.data.map((e: any) => e.id === id ? { ...e, opened: !e.opened } : e)
    }

    const isNoMatch = computed(() => !pinnedFiltered.value.length && !openActivitiesFiltered.value.length && !pastActivitiesFiltered.value.length && !!props.search)

    return {
      toggleOpenState,
      updateActivityCard,
      pinnedValue,
      isNoMatch,
      pinnedVisibleCount,
      isEmptyMode,
      pinnedFiltered,
      openActivitiesValue,
      openActivitiesFiltered,
      pastActivitiesValue,
      pastActivitiesFiltered,
      pastVisibleCount,
      toggleExpand,
    }
  },
})
