
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import { calendar } from '@/services/dateTimeService'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import type { AvatarColors } from '@/definitions/shared/types'

export default defineComponent({
  name: 'TimelineCard',
  components: {
    TmAvatar,
  },
  props: {
    avatarUrl: {
      type: String,
    },
    avatarIcon: {
      type: String,
    },
    avatarIconClass: {
      type: String,
      default: 'primary--text',
    },
    avatarColor: {
      type: String as PropType<AvatarColors>,
      default: 'white',
    },
    hideAvatar: {
      type: Boolean,
    },
    person: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
    showSelection: {
      type: Boolean,
    },
    yellow: {
      type: Boolean,
    },
    target: {
      type: String,
    },
    action: {
      type: String,
    },
    title: {
      type: String,
    },
    disableControls: {
      type: Boolean,
    },
    forceShowControls: {
      type: Boolean,
    },
    date: {
      type: String,
    },
    hideTop: {
      type: Boolean,
    },
  },
  emits: ['update:selected'],
  setup(props, context) {
    const hasBodySlot = context.slots.body
    const hasFooterSlot = context.slots.footer

    const avatarIconImage = ref(props.avatarIcon === 'whatsapp' || props.avatarIcon === 'facebook' || props.avatarIcon === 'gmail' || props.avatarIcon === 'outlook')

    return {
      calendar,
      hasBodySlot,
      hasFooterSlot,
      avatarIconImage,
    }
  },
})
