import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09773d6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-empty-state__body" }
const _hoisted_2 = { class: "tm-empty-state__title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "tm-empty-state__footer"
}
const _hoisted_5 = {
  key: 1,
  class: "tm-empty-state__subfooter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-empty-state", {
      [`tm-empty-state--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
      'tm-empty-state--with-subfooter': _ctx.$slots.subfooter,
    }])
  }, [
    _renderSlot(_ctx.$slots, "icon", {}, () => [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_tm_icon, {
            key: 0,
            class: "tm-empty-state__icon",
            "custom-size": _ctx.size === 'small' ? '48px' : '72px',
            name: _ctx.icon
          }, null, 8, ["custom-size", "name"]))
        : _createCommentVNode("", true)
    ], true),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["tm-empty-state__text", {'text-red': _ctx.redDescription}]),
              innerHTML: _ctx.description
            }, null, 10, _hoisted_3))
          : _createCommentVNode("", true)
      ])
    ], true),
    (_ctx.$slots.footer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.subfooter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "subfooter", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}