import type { FilterType } from '@/definitions/contacts/contactDetails/types'
import type { UseActivities, ActivityItemType } from '@/definitions/contacts/activities/types'

export const useActivities: UseActivities = () => {
  const activitiesList: ActivityItemType[] = [
    { name: 'Internal note', icon: 'tmi-add-note' },
    { name: 'Ticket', icon: 'email' },
    { name: 'Task', icon: 'assignment_turned_in' },
    { name: 'Deal', icon: 'tmi-sale' },
    { name: 'Reminder', icon: 'notifications' },
    { name: 'Event', icon: 'event' },
  ]

  const filtersList: FilterType[] = [
    {
      label: 'Calls',
      id: 'call',
    },
    {
      label: 'Live chats',
      id: 'liveChat',
    },
    {
      label: 'Mobile chats',
      id: 'mobileChat',
    },
    {
      label: 'Notes',
      id: 'note',
    },
    {
      label: 'Tickets',
      id: 'ticket',
    },
  ]

  return {
    filtersList,
    activitiesList,
  }
}
