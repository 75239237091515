
import type { PropType } from 'vue'
import { defineComponent, computed, ref } from 'vue'
import TimelineCard from '@/components/shared/templates/TimelineCard.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmAudioPlayer from '@/components/shared/TmAudioPlayer.vue'
import Highlight from '@/components/shared/Highlight.vue'
import type {
  ActivityCallDirections, ActivityCallTypes, ActivityCardStates, ActivityTypes, ActivityEmailTypes
} from '@/definitions/shared/templates/activity/types'
import type { StatusType } from '@/definitions/shared/types'
import type { RouteLocationRaw } from 'vue-router'
import ActivityCardInfo from '@/components/shared/templates/activity/ActivityCardInfo.vue'

type LinkType = {
  [key: string]: RouteLocationRaw;
}

const links: LinkType = {
  email: { name: 'base.contacts' },
  whatsappChat: { name: 'base.contacts' },
  facebookChat: { name: 'base.contacts' },
  mobileChat: { name: 'base.contacts' },
  call: { name: 'base.contacts' },
}

export default defineComponent({
  name: 'ActivityCard',
  components: {
    TmEllipsis,
    ActivityCardInfo,
    TmTooltip,
    TmButtonMore,
    Highlight,
    TmDropdownItem,
    TmDropdown,
    TmAudioPlayer,
    TimelineCard,
  },
  props: {
    author: {
      type: String,
    },
    cardState: {
      type: String as PropType<ActivityCardStates>,
    },
    pinned: {
      type: Boolean,
    },
    type: {
      type: String as PropType<ActivityTypes>,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    audio: {
      type: Object,
    },
    message: {
      type: String,
    },
    title: {
      type: String,
    },
    assignee: {
      type: Object,
    },
    callDirection: {
      type: String as PropType<ActivityCallDirections>,
    },
    status: {
      type: Object as PropType<StatusType>,
    },
    callType: {
      type: String as PropType<ActivityCallTypes>,
    },
    emailType: {
      type: String as PropType<ActivityEmailTypes>,
    },
    scheduled: {
      type: Boolean,
    },
  },
  emits: ['update:pinned'],
  setup(props) {
    const controlsShown = ref(false)

    const typeIcon = computed(() => {
      switch (props.type) {
        case 'mobileChat': return props.scheduled ? 'event' : 'chat'
        case 'whatsappChat': return 'whatsapp'
        case 'facebookChat': return 'facebook'
        case 'call':
          return props.callType === 'missed'
            ? 'phone_missed'
            : props.callDirection === 'outbound'
              ? 'tmi-phone-forwarded'
              : 'phone_callback'
        default: return props.emailType === 'gmail' ? 'gmail' : 'outlook'
      }
    })

    const link = computed(() => links[props.type])
    const badgeText = computed(() => props.type !== 'call' ? props.type.replace(/([A-Z])/, ' $1') : `${props.callDirection} call`)

    return {
      badgeText,
      link,
      controlsShown,
      typeIcon,
    }
  },
})
