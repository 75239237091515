
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { StatusType } from '@/definitions/shared/types'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmTooltip,
    TmAvatar,
    TmStatus,
  },
  props: {
    assignee: {
      type: Object,
    },
    status: {
      type: Object as PropType<StatusType>,
    },
  },
})
