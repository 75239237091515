import { activityStatusOptions } from '@/definitions/_general/_data/optionsList'
import type { ActivityCardType } from '@/definitions/shared/templates/activity/types'

export const contactActivities: ActivityCardType[] = [
  {
    id: 1,
    type: 'call',
    cardState: 'past',
    callType: 'finished',
    callDirection: 'inbound',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Inbound call from Ralph Edwards',
    audio: {
      duration: '1:44',
      progress: 50,
    },
  },
  {
    id: 2,
    cardState: 'past',
    type: 'whatsappChat',
    date: new Date(+new Date() - (2 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'WhatsApp chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'Let me investigate the logs to identify the problem. While I look into this, is there anything else I can assist you with?',
  },
  {
    id: 3,
    cardState: 'past',
    type: 'email',
    emailType: 'gmail',
    date: new Date(+new Date() - (3 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'I need help with my account',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Danny',
      lastName: 'Schmeler',
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      avatarColor: 'gray',
    },
    message: 'Hey, yes, please. AU virtual numbers and registration, Just try new feature and link it inside toolbar “Attach” icon',
  },
  {
    id: 4,
    cardState: 'past',
    type: 'facebookChat',
    date: new Date(+new Date() - (4 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'WhatsApp chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'No, this is my main concern at the moment. Please help me with resolving this issue. 😉 👍',
  },
  {
    id: 5,
    cardState: 'past',
    type: 'email',
    emailType: 'outlook',
    date: new Date(+new Date() - (4 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'I\'m having trouble accessing my account',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'I apologize for the delay. Let me check the status of your order for you.',
  },
  {
    id: 6,
    cardState: 'past',
    type: 'email',
    emailType: 'gmail',
    date: new Date(+new Date() - (5 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'How can I update my payment method?',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Danny',
      lastName: 'Schmeler',
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      avatarColor: 'gray',
    },
    message: 'To update your payment method, go to your account settings and select \'Payment Options.\'',
  },
  {
    id: 7,
    cardState: 'past',
    type: 'whatsappChat',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'WhatsApp chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'You can update your contact information by going to your profile settings. Let me walk you through it.',
  },
  {
    id: 8,
    type: 'call',
    cardState: 'past',
    callType: 'missed',
    callDirection: 'inbound',
    avatarIconClass: 'error--text',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Missed call from Ralph Edwards',
  },
  {
    id: 9,
    cardState: 'past',
    type: 'mobileChat',
    date: new Date(+new Date() - (4 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'The latest update brings some exciting features. Let me list them for you.',
  },
  {
    id: 10,
    cardState: 'past',
    type: 'email',
    emailType: 'outlook',
    date: new Date(+new Date() - (24 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Can you explain the renewal process?',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'Renewing is easy. You can renew your subscription by following these steps.',
  },
  {
    id: 11,
    cardState: 'past',
    type: 'mobileChat',
    date: new Date(+new Date() - (25 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'Sure, just click CTA on the main screen and wait 2 seconds',
  },
  {
    id: 12,
    cardState: 'past',
    type: 'email',
    emailType: 'gmail',
    date: new Date(+new Date() - (25 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Can you help me reset my password?',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Danny',
      lastName: 'Schmeler',
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      avatarColor: 'gray',
    },
    message: 'You\'re welcome! If you have any more questions, feel free to ask.',
  },
  {
    id: 13,
    type: 'call',
    cardState: 'past',
    callType: 'missed',
    callDirection: 'inbound',
    avatarIconClass: 'error--text',
    date: new Date(+new Date() - (26 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Missed call from Ralph Edwards',
    audio: {
      duration: '1:03',
      progress: 16,
    },
  },
  {
    id: 14,
    type: 'call',
    cardState: 'past',
    callType: 'finished',
    callDirection: 'inbound',
    date: new Date(+new Date() - (26 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Outbound call to Ralph Edwards',
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    audio: {
      duration: '1:44',
      progress: 66,
    },
  },
  {
    id: 15,
    type: 'call',
    cardState: 'past',
    callType: 'finished',
    callDirection: 'inbound',
    date: new Date(+new Date() - (66 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Outbound call to Ralph Edwards',
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    audio: {
      duration: '1:44',
      progress: 66,
    },
  },
  {
    id: 16,
    cardState: 'past',
    type: 'email',
    emailType: 'gmail',
    date: new Date(+new Date() - (65 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Customer support needed',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Danny',
      lastName: 'Schmeler',
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      avatarColor: 'gray',
    },
    message: 'Sure, just click CTA on the main screen and wait 2 seconds.',
  },
  {
    id: 17,
    cardState: 'past',
    type: 'mobileChat',
    date: new Date(+new Date() - (66 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'Sure, just click CTA on the main screen and wait 2 seconds',
  },
  {
    id: 18,
    cardState: 'past',
    type: 'note',
    assignee: {
      firstName: 'Mervin',
      lastName: 'Purdy',
      avatar: 'https://randomuser.me/api/portraits/women/58.jpg',
      avatarColor: 'crimson',
    },
    title: 'Followed up with the client after our last meeting. They expressed interest in our new product and requested a demo. Scheduled a follow-up call for next week.',
    date: new Date(+new Date() - (68 * 24 * 48 * 60 * 1000)).toJSON(),
  },
  {
    id: 19,
    cardState: 'past',
    type: 'email',
    emailType: 'outlook',
    date: new Date(+new Date() - (69 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Can you guide me through new features?',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'Great to hear you\'re interested! I\'ve registered you for the webinar. You\'ll receive a confirmation email shortly.',
  },
  {
    id: 20,
    cardState: 'open',
    type: 'mobileChat',
    date: new Date(+new Date() - (24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'open'),
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'Certainly! I can assist you with resetting your password. Could you please provide your username?',
  },
  {
    id: 21,
    cardState: 'open',
    type: 'mobileChat',
    date: new Date(+new Date() - (25 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'pending'),
    assignee: {
      firstName: 'Mervin',
      lastName: 'Purdy',
      avatar: 'https://randomuser.me/api/portraits/women/58.jpg',
      avatarColor: 'crimson',
    },
    message: 'Followed up with the client after our last meeting. They expressed interest in our new product and requested a demo. Scheduled a follow-up call for next week.',
  },
  {
    id: 22,
    cardState: 'open',
    type: 'email',
    emailType: 'outlook',
    date: new Date(+new Date() - (2 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Images attach process',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'Just try new feature and link it inside toolbar “Attach” icon',
  },
  {
    id: 23,
    cardState: 'open',
    type: 'mobileChat',
    scheduled: true,
    date: new Date(+new Date() + (24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'scheduled'),
    message: 'Sure, just click CTA on the main screen and wait 2 seconds',
  },
  {
    id: 24,
    type: 'note',
    pinned: true,
    cardState: 'open',
    assignee: {
      firstName: 'Mervin',
      lastName: 'Purdy',
      avatar: 'https://randomuser.me/api/portraits/women/58.jpg',
      avatarColor: 'crimson',
    },
    title: 'This is our key client, please be extra polite with her.',
    date: new Date(+new Date() - (40 * 60 * 1000)).toJSON(),
  },
  {
    id: 25,
    type: 'mobileChat',
    pinned: true,
    date: new Date(+new Date() - (5 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Chat with (843) 998-6443',
    cardState: 'past',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    assignee: {
      firstName: 'Katelyn',
      lastName: 'Dicki',
      avatar: 'https://randomuser.me/api/portraits/men/41.jpg',
      avatarColor: 'blue',
    },
    message: 'Hello. I have an issue with access to my account, can you check system status?',
  },
  {
    id: 26,
    pinned: true,
    cardState: 'open',
    type: 'whatsappChat',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'WhatsApp chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'open'),
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'You can update your contact information by going to your profile settings. Let me walk you through it.',
  },
]

export const organizationActivities: ActivityCardType[] = [
  {
    id: 1,
    type: 'call',
    cardState: 'past',
    callType: 'finished',
    callDirection: 'inbound',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Inbound call from Ralph Edwards',
    author: 'Jennifer Crowford',
    audio: {
      duration: '1:44',
      progress: 50,
    },
  },
  {
    id: 2,
    cardState: 'past',
    type: 'whatsappChat',
    date: new Date(+new Date() - (2 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'WhatsApp chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'Let me investigate the logs to identify the problem. While I look into this, is there anything else I can assist you with?',
  },
  {
    id: 3,
    cardState: 'past',
    type: 'email',
    emailType: 'gmail',
    date: new Date(+new Date() - (3 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'I need help with my account',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Danny',
      lastName: 'Schmeler',
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      avatarColor: 'gray',
    },
    message: 'Hey, yes, please. AU virtual numbers and registration, Just try new feature and link it inside toolbar “Attach” icon',
  },
  {
    id: 4,
    cardState: 'past',
    type: 'facebookChat',
    date: new Date(+new Date() - (4 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'WhatsApp chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'No, this is my main concern at the moment. Please help me with resolving this issue. 😉 👍',
  },
  {
    id: 5,
    cardState: 'past',
    type: 'email',
    emailType: 'outlook',
    date: new Date(+new Date() - (4 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'I\'m having trouble accessing my account',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'I apologize for the delay. Let me check the status of your order for you.',
  },
  {
    id: 6,
    cardState: 'past',
    type: 'email',
    emailType: 'gmail',
    date: new Date(+new Date() - (5 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'How can I update my payment method?',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Danny',
      lastName: 'Schmeler',
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      avatarColor: 'gray',
    },
    message: 'To update your payment method, go to your account settings and select \'Payment Options.\'',
  },
  {
    id: 7,
    cardState: 'past',
    type: 'whatsappChat',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'WhatsApp chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'You can update your contact information by going to your profile settings. Let me walk you through it.',
  },
  {
    id: 8,
    type: 'call',
    cardState: 'past',
    callType: 'missed',
    callDirection: 'inbound',
    avatarIconClass: 'error--text',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    author: 'Jennifer Crowford',
    title: 'Missed call from Ralph Edwards',
  },
  {
    id: 9,
    cardState: 'past',
    type: 'mobileChat',
    date: new Date(+new Date() - (4 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'The latest update brings some exciting features. Let me list them for you.',
  },
  {
    id: 10,
    cardState: 'past',
    type: 'email',
    emailType: 'outlook',
    date: new Date(+new Date() - (24 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Can you explain the renewal process?',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'Renewing is easy. You can renew your subscription by following these steps.',
  },
  {
    id: 11,
    cardState: 'past',
    type: 'mobileChat',
    date: new Date(+new Date() - (25 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'Sure, just click CTA on the main screen and wait 2 seconds',
  },
  {
    id: 12,
    cardState: 'past',
    type: 'email',
    emailType: 'gmail',
    date: new Date(+new Date() - (25 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Can you help me reset my password?',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Danny',
      lastName: 'Schmeler',
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      avatarColor: 'gray',
    },
    message: 'You\'re welcome! If you have any more questions, feel free to ask.',
  },
  {
    id: 13,
    type: 'call',
    cardState: 'past',
    callType: 'missed',
    callDirection: 'inbound',
    avatarIconClass: 'error--text',
    date: new Date(+new Date() - (26 * 24 * 48 * 60 * 1000)).toJSON(),
    author: 'Jennifer Crowford',
    title: 'Missed call from Ralph Edwards',
    audio: {
      duration: '1:03',
      progress: 16,
    },
  },
  {
    id: 14,
    type: 'call',
    cardState: 'past',
    callType: 'finished',
    callDirection: 'inbound',
    date: new Date(+new Date() - (26 * 24 * 48 * 60 * 1000)).toJSON(),
    author: 'Jennifer Crowford',
    title: 'Outbound call to Ralph Edwards',
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    audio: {
      duration: '1:44',
      progress: 66,
    },
  },
  {
    id: 15,
    type: 'call',
    cardState: 'past',
    callType: 'finished',
    callDirection: 'inbound',
    date: new Date(+new Date() - (66 * 24 * 48 * 60 * 1000)).toJSON(),
    author: 'Jennifer Crowford',
    title: 'Outbound call to Ralph Edwards',
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    audio: {
      duration: '1:44',
      progress: 66,
    },
  },
  {
    id: 16,
    cardState: 'past',
    type: 'email',
    emailType: 'gmail',
    date: new Date(+new Date() - (65 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Customer support needed',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Danny',
      lastName: 'Schmeler',
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
      avatarColor: 'gray',
    },
    message: 'Sure, just click CTA on the main screen and wait 2 seconds.',
  },
  {
    id: 17,
    cardState: 'past',
    type: 'mobileChat',
    date: new Date(+new Date() - (66 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'Sure, just click CTA on the main screen and wait 2 seconds',
  },
  {
    id: 18,
    cardState: 'past',
    type: 'note',
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Mervin',
      lastName: 'Purdy',
      avatar: 'https://randomuser.me/api/portraits/women/58.jpg',
      avatarColor: 'crimson',
    },
    title: 'Followed up with the client after our last meeting. They expressed interest in our new product and requested a demo. Scheduled a follow-up call for next week.',
    date: new Date(+new Date() - (68 * 24 * 48 * 60 * 1000)).toJSON(),
  },
  {
    id: 19,
    cardState: 'past',
    type: 'email',
    emailType: 'outlook',
    date: new Date(+new Date() - (69 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Can you guide me through new features?',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'Great to hear you\'re interested! I\'ve registered you for the webinar. You\'ll receive a confirmation email shortly.',
  },
  {
    id: 20,
    cardState: 'open',
    type: 'mobileChat',
    date: new Date(+new Date() - (24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'open'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'Certainly! I can assist you with resetting your password. Could you please provide your username?',
  },
  {
    id: 21,
    cardState: 'open',
    type: 'mobileChat',
    date: new Date(+new Date() - (25 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'pending'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Mervin',
      lastName: 'Purdy',
      avatar: 'https://randomuser.me/api/portraits/women/58.jpg',
      avatarColor: 'crimson',
    },
    message: 'Followed up with the client after our last meeting. They expressed interest in our new product and requested a demo. Scheduled a follow-up call for next week.',
  },
  {
    id: 22,
    cardState: 'open',
    type: 'email',
    emailType: 'outlook',
    date: new Date(+new Date() - (2 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Images attach process',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Ruth',
      lastName: 'Murphy',
      avatarColor: 'crimson',
    },
    message: 'Just try new feature and link it inside toolbar “Attach” icon',
  },
  {
    id: 23,
    cardState: 'open',
    type: 'mobileChat',
    scheduled: true,
    date: new Date(+new Date() + (24 * 48 * 60 * 1000)).toJSON(),
    title: 'Mobile chat with Ralph Edwards',
    author: 'Jennifer Crowford',
    status: activityStatusOptions.find(status => status.name === 'scheduled'),
    message: 'Sure, just click CTA on the main screen and wait 2 seconds',
  },
  {
    id: 24,
    type: 'note',
    pinned: true,
    cardState: 'open',
    assignee: {
      firstName: 'Mervin',
      lastName: 'Purdy',
      avatar: 'https://randomuser.me/api/portraits/women/58.jpg',
      avatarColor: 'crimson',
    },
    author: 'Jennifer Crowford',
    title: 'This is our key client, please be extra polite with her.',
    date: new Date(+new Date() - (40 * 60 * 1000)).toJSON(),
  },
  {
    id: 25,
    type: 'mobileChat',
    pinned: true,
    date: new Date(+new Date() - (5 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'Chat with (843) 998-6443',
    cardState: 'past',
    status: activityStatusOptions.find(status => status.name === 'solved'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Katelyn',
      lastName: 'Dicki',
      avatar: 'https://randomuser.me/api/portraits/men/41.jpg',
      avatarColor: 'blue',
    },
    message: 'Hello. I have an issue with access to my account, can you check system status?',
  },
  {
    id: 26,
    pinned: true,
    cardState: 'open',
    type: 'whatsappChat',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    title: 'WhatsApp chat with Ralph Edwards',
    status: activityStatusOptions.find(status => status.name === 'open'),
    author: 'Jennifer Crowford',
    assignee: {
      firstName: 'Kenyatta',
      lastName: 'Johnson',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
      avatarColor: 'orange',
    },
    message: 'You can update your contact information by going to your profile settings. Let me walk you through it.',
  },
]
