
import { defineComponent } from 'vue'
import type { SizeProp } from '@/definitions/shared/types'
import _ from 'lodash'

export default defineComponent({
  name: 'TmEmptyState',
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    size: {
      type: String as SizeProp<'' | 'medium' | 'small' | 'xSmall'>,
      default: '',
    },
    redDescription: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      kebabCase: _.kebabCase,
    }
  },
})
