import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment, renderSlot as _renderSlot, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-514c1c14"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "timeline-card__avatar"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "timeline-card__inner" }
const _hoisted_4 = {
  key: 0,
  class: "timeline-card__checkbox"
}
const _hoisted_5 = { class: "flex-grow-1 w100pr flex-shrink-1" }
const _hoisted_6 = {
  key: 0,
  class: "timeline-card__top-row"
}
const _hoisted_7 = { class: "body-text-regular-14 lh-20 mr-2" }
const _hoisted_8 = { class: "font-weight-normal" }
const _hoisted_9 = { class: "timeline-card__top-date" }
const _hoisted_10 = { class: "timeline-card__top-right-controls" }
const _hoisted_11 = { class: "timeline-card__bottom" }
const _hoisted_12 = { class: "timeline-card__bottom-date" }
const _hoisted_13 = {
  key: 0,
  class: "timeline-card__bottom-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["timeline-card", {
      'timeline-card--yellow': _ctx.yellow,
      'timeline-card--selected': _ctx.selected
    }])
  }, [
    (!_ctx.hideAvatar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (!_ctx.avatarIcon)
            ? (_openBlock(), _createBlock(_component_tm_avatar, {
                key: 0,
                url: _ctx.avatarUrl,
                color: _ctx.avatarColor,
                tooltip: _ctx.person,
                "custom-icon-class": _ctx.avatarIconClass,
                name: _ctx.person,
                "custom-size": "32px"
              }, null, 8, ["url", "color", "tooltip", "custom-icon-class", "name"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.avatarIconImage)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: require(`@/assets/images/logos/${_ctx.avatarIcon}.svg`),
                      alt: ""
                    }, null, 8, _hoisted_2))
                  : (_openBlock(), _createBlock(_component_tm_icon, {
                      key: 1,
                      name: _ctx.avatarIcon,
                      size: "large",
                      class: _normalizeClass(_ctx.avatarIconClass)
                    }, null, 8, ["name", "class"]))
              ], 64))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showSelection)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "checkbox", {}, () => [
              _createVNode(_component_tm_field, {
                "model-value": _ctx.selected,
                type: "checkbox",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:selected', $event)))
              }, null, 8, ["model-value"])
            ], true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.hideTop)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "title", {}, () => [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.title)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.title), 1)
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.person) + " ", 1),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.action), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.target), 1)
                      ], 64))
                ])
              ], true),
              _createElementVNode("div", {
                class: _normalizeClass(["timeline-card__top-right", {
              'timeline-card__top-right--with-ctrls': !_ctx.disableControls,
              'timeline-card__top-right--show-ctrls': _ctx.forceShowControls,
            }])
              }, [
                _createElementVNode("div", _hoisted_9, [
                  _renderSlot(_ctx.$slots, "date", {}, () => [
                    _createTextVNode(_toDisplayString(_ctx.calendar(_ctx.date)), 1)
                  ], true)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _renderSlot(_ctx.$slots, "controls", {}, undefined, true)
                ])
              ], 2)
            ]))
          : _createCommentVNode("", true),
        (_ctx.hasBodySlot)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["timeline-card__body", {'timeline-card__body--without-top': _ctx.hideTop}])
            }, [
              _renderSlot(_ctx.$slots, "body", {}, undefined, true)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _renderSlot(_ctx.$slots, "date", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.calendar(_ctx.date)), 1)
            ], true)
          ]),
          (_ctx.$slots['bottom-right'])
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _renderSlot(_ctx.$slots, "bottom-right", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 2))
}