
import { defineComponent } from 'vue'
import DropdownFilterCheckbox from '@/components/shared/filters/dropdown/DropdownFilterCheckbox.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import ContactDetailsNavigationContainer from '@/components/pages/contacts/organizationDetails/ContactDetailsNavigationContainer.vue'
import type { PropType } from 'vue'
import type { FiltersListType } from '@/definitions/shared/filters/types'

export default defineComponent({
  components: {
    DropdownFilterCheckbox,
    DropdownFilterRadio,
    DropdownFilterAssignee,
    ContactDetailsNavigationContainer,
  },
  props: {
    filters: {
      type: Object as PropType<FiltersListType>,
    },
  },
})
