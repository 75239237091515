import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bf24ec24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-audio-player__time" }
const _hoisted_2 = { class: "tm-audio-player__progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-audio-player", {
      'tm-audio-player--outlined': _ctx.outlined,
      'tm-audio-player--fullwidth': _ctx.fullWidth,
      [`tm-audio-player--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
    }])
  }, [
    _createElementVNode("div", {
      class: "tm-audio-player__play-btn",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePlay && _ctx.togglePlay(...args)))
    }, [
      _createVNode(_component_tm_icon, {
        name: _ctx.isPlaying ? 'pause' : 'play_arrow',
        size: _ctx.size === 'large' ? 'xLarge' : '',
        class: "primary--text"
      }, null, 8, ["name", "size"])
    ]),
    _createElementVNode("div", _hoisted_1, "0:00 / " + _toDisplayString(_ctx.duration), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "tm-audio-player__progress__bar",
        style: _normalizeStyle({
          width: _ctx.progress + '%'
        })
      }, null, 4)
    ]),
    (_ctx.showMute)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 0,
          class: "ml-4 neutral--text pointer",
          name: _ctx.isMuted ? 'volume_off' : 'volume_up',
          size: "xxLarge",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isMuted = !_ctx.isMuted))
        }, null, 8, ["name"]))
      : _createCommentVNode("", true)
  ], 2))
}