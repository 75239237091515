
import { defineComponent, computed } from 'vue'
import ActivityCard from '@/components/shared/templates/activity/ActivityCard.vue'
import NoteCard from '@/components/shared/templates/NoteCard.vue'
import type { PropType } from 'vue'
import type { ActivityTypes } from '@/definitions/shared/templates/activity/types'

export default defineComponent({
  components: {
    ActivityCard,
    NoteCard,
  },
  props: {
    type: {
      type: String as PropType<ActivityTypes>,
      required: true,
    },
  },
  setup(props) {
    const componentName = computed(() => props.type === 'note' ? 'note-card' : 'activity-card')

    return {
      componentName,
    }
  },
})
