import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13a3041d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "activity-card-info" }
const _hoisted_2 = {
  key: 1,
  class: "ml-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status)
      ? (_openBlock(), _createBlock(_component_tm_status, {
          key: 0,
          color: _ctx.status.color,
          outline: _ctx.status.outline,
          name: _ctx.status.name
        }, null, 8, ["color", "outline", "name"]))
      : _createCommentVNode("", true),
    (_ctx.assignee)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_tm_avatar, {
            url: _ctx.assignee.avatar,
            color: _ctx.assignee.avatarColor,
            name: _ctx.assignee.firstName,
            size: "small"
          }, null, 8, ["url", "color", "name"]),
          _createVNode(_component_tm_tooltip, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.assignee.firstName) + " " + _toDisplayString(_ctx.assignee.lastName), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}