
import { defineComponent, ref } from 'vue'
import type { SizeProp } from '@/definitions/shared/types'
import _ from 'lodash'

export default defineComponent({
  props: {
    duration: {
      type: String,
    },
    outlined: {
      type: Boolean,
    },
    progress: {
      type: Number,
    },
    fullWidth: {
      type: Boolean,
    },
    showMute: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<'large' | ''>,
      default: '',
    },
  },
  setup() {
    const isPlaying = ref(false)
    const isMuted = ref(false)
    const togglePlay = () => {
      isPlaying.value = !isPlaying.value
    }
    return {
      isMuted,
      isPlaying,
      togglePlay,
      kebabCase: _.kebabCase,
    }
  },
})
